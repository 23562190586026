// import Nav from './nav';
import { Button, CardMedia, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, SyntheticEvent, useState } from 'react';
import theme from '../styles/theme';

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
  },
  image: {
    width: 176,
    height: 40,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  title: {
    fontSize: '20px',
    letterSpacing: '0.04px',
    lineHeight: '1.24em',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 900,
    color: '#FFFFFF',
  },
}));

interface HeaderProps {
  title: string;
}

const Header: FC<HeaderProps> = ({ title }: HeaderProps) => {
  const [showBurger, setShowBurger] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const handleClick = async (e: SyntheticEvent) => {
    router.push(router.pathname, router.pathname, { locale: e.currentTarget.id });
  };

  const handleBurgerClick = () => {
    setShowBurger(!showBurger);
  };

  const handleButtonClick = () => {
    router.push('https://www.punamustamedia.fi/tietosuoja/');
  };

  return (
    <>
      <div className="header">
        <div className="desktop-container">
          <Typography className={classes.title}>{title}</Typography>
          <div className="logo-container">
            <Link href="/">
              <a href="/">
                <CardMedia
                  className={classes.image}
                  image="/static/images/PunaMusta_logo_white.svg"
                ></CardMedia>
              </a>
            </Link>
            <Button className={classes.button} id="fi" onClick={handleClick}>
              {t('language_fi')}
            </Button>
            <Button className={classes.button} id="en" onClick={handleClick}>
              {t('language_en')}
            </Button>
          </div>
        </div>
        <div className="mobile-container">
          <button onClick={handleBurgerClick} className="menu-button">
            <img
              alt="menu-icon"
              src={showBurger ? '/static/images/x.svg' : '/static/images/menu_icon.svg'}
            ></img>
          </button>
          <Link href="/">
            <a href="/">
              <img alt="logo" src="/static/images/pumu_logo_white.png"></img>
            </a>
          </Link>
        </div>
      </div>
      {showBurger && (
        <div
          style={{
            zIndex: 20,
            position: 'absolute',
            top: 60,
            width: '100%',
            background: '#e20815',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '10px',
          }}
        >
          <Button className={classes.button} id="fi" onClick={handleClick}>
            {t('language_fi')}
          </Button>
          <Button className={classes.button} id="en" onClick={handleClick}>
            {t('language_en')}
          </Button>
          <Button className={classes.button} id="en" onClick={handleButtonClick}>
            {t('footer.leftText')}
          </Button>
        </div>
      )}{' '}
      <style jsx>{`
        .header {
          display: flex;
          height: 56px;
          background: #e20815;
          padding: 10px;
          align-items: center;
          z-index: 2;
        }
        .logo-container {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
        }
        .mobile-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .menu-button {
          border: none;
          background: none;
          cursor: pointer;
        }
        .desktop-container {
          display: none;
        }
        .circle {
          width: 100%;
          height: 50px;
          background: #e20815;
          border-bottom-left-radius: 100%; /* 100px of height + 10px of border */
          border-bottom-right-radius: 100%;
        }
        @media only screen and (min-width: ${theme.breakpoints.values.md}px) {
          .circle {
            display: none;
          }
          .mobile-container {
            display: none;
          }
          .desktop-container {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default Header;
