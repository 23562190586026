// import Nav from './nav';
import { CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import theme from '../styles/theme';

const useStyles = makeStyles(() => ({
  footer: {
    height: '56px',
    background: 'black',
    flexShrink: 0,
    paddingLeft: 20,
    paddingRight: 20,
    position: 'sticky',
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  button: {
    color: 'white',
  },
  image: {
    width: 132,
    height: 30,
  },
  leftText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  text: {
    letterSpacing: '0.15px',
    marginRight: 10,
    color: 'white',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
}));

const Footer: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Grid className={classes.footer} alignItems="center" justifyContent="space-between" container>
      <a href="https://www.punamustamedia.fi/tietosuoja/" target={'_blank'} rel="noreferrer">
        <Typography className={`${classes.text} ${classes.leftText}`}>
          {t('footer.leftText')}
        </Typography>
      </a>

      <Grid className={classes.logoContainer} item>
        <Grid container alignItems="center">
          <Typography className={classes.text}>{t('footer.rightText')}</Typography>
          <CardMedia
            className={classes.image}
            image="/static/images/PunaMusta_logo_white.svg"
          ></CardMedia>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
