import { CardMedia, Grid, useMediaQuery } from '@material-ui/core';
import React, { FC } from 'react';

import Footer from './footer';
import Head from 'next/head';
import Header from './header';
import MobileSubHeader from './mobile-sub-header';
import theme from '../styles/theme';
import { useTranslation } from 'next-i18next';
interface LayoutProps {
  children: React.ReactNode;
  tab?: string;
  backgroundImage?: boolean;
  title: string;
  showSubtitle?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  backgroundImage = true,
  title,
  showSubtitle = false,
}: LayoutProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const subtitle = showSubtitle ? t(`header.${title}.subtitle`) : undefined;
  return (
    <>
      <Head>
        <title>{t(`tab.${title}`)}</title>
      </Head>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        style={{ minHeight: '100%', position: 'relative' }}
      >
        {backgroundImage && (
          <CardMedia
            image="/static/images/CurveLine.svg"
            style={{ width: '100%', height: '100%', position: 'absolute', zIndex: -2 }}
          ></CardMedia>
        )}

        <Header title={t(`header.${title}.${isMobile ? 'mobile-title' : 'title'}`)}></Header>
        <MobileSubHeader
          subtitle={subtitle}
          title={t(`header.${title}.${isMobile ? 'mobile-title' : 'title'}`)}
        ></MobileSubHeader>
        <div className="page-content-container">{children}</div>

        <Footer></Footer>
      </Grid>
      <style jsx>{`
        .page-content-container {
          display: flex;
          flex: 1 0 auto;
          max-width: 100%;
          padding: 20px;
          padding-top: 40px;
          background: #b1b8c027;
        }
        @media only screen and (min-width: ${theme.breakpoints.values.md}px) {
          .page-content-container {
            padding: 60px;
          }
        }
      `}</style>
    </>
  );
};

export default Layout;
