import theme from '../styles/theme';

interface Props {
  title: string;
  subtitle?: string;
}

const MobileSubHeader: React.FC<Props> = ({ title, subtitle }: Props) => {
  return (
    <div className="mobile-sub-header-container">
      <div className="title-container">
        {subtitle && <h2 className="subtitle">{subtitle}</h2>}
        <h1 className="title">{title}</h1>
      </div>
      <div className="circle"></div>

      <style jsx>{`
        .mobile-sub-header-container {
          position: relative;
        }

        .title-container {
          display: flex;
          flex-direction: column;
          background: #e20815;
          align-items: center;
          width: 100%;
          justify-content: center;
        }
        .subtitle {
          font-size: 12px;
          letter-spacing: 1.88px;
          text-transform: uppercase;
          line-height: 1.15em;
          font-family: 'Inter', sans-serif;
          font-weight: bold;
          color: #ffffff;
          margin: 0;
          margin-bottom: 5px;
          z-index: 1;
        }
        .title {
          font-size: 24px;
          letter-spacing: 0.04px;
          line-height: 1.29em;
          font-family: 'Inter', sans-serif;
          font-weight: 400;
          color: #ffffff;
          margin: 0;
          text-align: center;
          z-index: 1;
        }

        .circle {
          position: absolute;
          margin-top: -170px;
          left: -25%;
          z-index: 0;
          width: 150%;
          height: 200px;
          background: #e20815;
          border-bottom-left-radius: 100%;
          border-bottom-right-radius: 100%;
        }
        @media only screen and (min-width: ${theme.breakpoints.values.md}px) {
          .mobile-sub-header-container {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default MobileSubHeader;
